import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

const FurnishingIkea = () => {
  const data = useStaticQuery(graphql`
    query {
      ikea: file(relativePath: { eq: "ikea-building-1797405.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      measurement: file(relativePath: { eq: "plan-2092499_1920.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      personWithIpad: file(
        relativePath: { eq: "person_with_ipad_and_plan.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hotdog: file(relativePath: { eq: "hot-dog-2436748_1920.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout maxWidth={600}>
      <SEO title="Furnishing: a study of an IKEA trip." 
      description="Learn how PanoRoom can help you in re-furnishing your apartment: let's go on a trip to IKEA!"
      // image={data.ikea.childImageSharp}
      />
      <div style={{ padding: "0px 1em" }}>
        <h1>Furnishing: a study of an IKEA trip.</h1>

        <Img
          style={{ marginBottom: "1.45rem" }}
          {...data.ikea.childImageSharp}
        />

        <p>
          Let’s face it: everyone was at an IKEA at some point of their life.
          Whether as a toddler (so not entirely voluntarily), a kid (more so) or
          an adult looking for cheap furniture. The magnetic allure of the
          Swedish furnishing giant invariably leads to a maze filled with sofas,
          chairs and fruit-scented candles.
        </p>

        <p>
          Among many symbols of power Ikea possess (meatballs, bags, hot-dogs,
          you name it), there is one which exists in an apparent symbiosis with
          note & pencil: a paper ruler. Aside from letting you know whether a
          new pillow will fit onto your BEDDINGE, it also serves another
          purpose: to help you arrange the furniture into your flat. While
          making perfect sense, one vital piece of information is still missing:
          what is the exact size of the place you want to fit your sofa into?
        </p>

        <Img
          style={{ marginBottom: "1.45rem" }}
          {...data.measurement.childImageSharp}
        />

        <p>
          While we usually know what the size of the place we wanted to buy a
          particular piece of furniture for is, sometimes we want to buy
          something spontaneously (being the perfect customer, in fact) or to
          see how that piece fits somewhere else. Unfortunately though, we
          rarely have accurate plans of our entire apartment (making Ikeas heart
          bleed). And even when we do, they are somewhere in BILLY rather than
          in our pocket.
        </p>

        <p>
          There are multiple reasons for this state of affairs, but I won’t talk
          about them in this post (you can read about them in another post
          “Realtors and floor plans”). While most of those issues can sometimes
          be overcome, they usually don’t give a solution that can be used
          practically. Suppose one takes a picture of a precise, architectural
          plan. Although being exact down to an inch-level, they are hard to
          work with on your mobile device: it takes a large effort to take
          measurements on-the-go as you try to shop for different pieces of
          furniture.
        </p>

        <p>
          Until recently, most of the home-planning apps were offering subpar
          performance comparing to classical solutions. This was mostly because
          they were not designed to work with obstructed corners, a scenario
          more than commonly met in real life. This led to measurements which
          were sometimes off by even half a meter (3 feet), making the plans
          unreliable.
        </p>

        <Img
          style={{ marginBottom: "1.45rem" }}
          {...data.personWithIpad.childImageSharp}
        />

        <p>
          At PanoRoom, we have overcome this problem by using a a simple &
          innovative way to mark walls. Our interface is both incredibly
          intuitive and allows high performance for power users. We believe that
          by getting to the edge posed by the technology itself we can give the
          customers accuracy they need.
        </p>
        <p>
          Having created a plan in advance, which takes about ten minutes, it is
          possible to see if any bed, sofa or the night table will obediently
          fit into the corner we envision them to stand in. Rather than
          discussing the remembered dimension in terms of ells or different
          handspans one can just instantly see if the desired piece of furniture
          will just fit.
        </p>
        <p>
          As a company, we hope that our app will help you in your next
          shopping. Whether you want to buy a used sofa from Craigslist, a new
          table from IKEA or a night table from a designer store you count on us
          to get the fitting part right. You can spend the saved time on eating
          IKEA buffet hotdogs, or chilling on a newly bought sofa. Enjoy!
        </p>
        <Img
          style={{ marginBottom: "1.45rem" }}
          {...data.hotdog.childImageSharp}
        />
      </div>
    </Layout>
  );
};

export default FurnishingIkea;
